<template>
  <div>
    <page-title :title="`Security Settings`"></page-title>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
						<h4 class="card-title">Change Password</h4>
            <div class="alert alert-danger" v-if="error">{{ error }}</div>
            <div class="alert alert-success" v-if="updated">Your password has been updated</div>
            <form @submit.prevent="submit">
              <div class="form-group">
                <label>Current Password</label>
                <input required type="password" class="form-control" v-model="password.current_password" placeholder="Current Password" />
              </div>
              <div class="form-group">
                <label>New Password</label>
                <input required type="password" class="form-control" v-model="password.new_password" placeholder="New Password" />
              </div>
              <div class="form-group">
                <label>Confirm New Password</label>
                <input required
                  type="password"
                  class="form-control"
                  v-model="password.new_password_confirmation"
                  placeholder="Confirm New Password"
                />
              </div>
              <div class="form-group text-right">
                <button class="btn btn-secondary">Change Password</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: {
        current_password: "",
        new_password: "",
        new_password_confirmation: ""
      },
      error: false,
      updated: false
    };
  },

  methods: {
    submit() {
      this.error = false
      this.updated = false
      this.$axios
        .post("/api/v1/dashboard/update-password", this.password)
        .then(() => {
          this.updated = true
        })
        .catch(error => {
          this.error = error.response.data
        });
    }
  }
};
</script>